import { makeStyles } from '@material-ui/core';
import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import content from '../content/dataPrivacy/page.json';
import FiraSans from '../fonts/Fira_Sans/firaSans';
import Colors from '../styles/colors';
import Dimensions, { narrowWidth } from '../styles/dimensions';

const DataProtection = () => {
	const classes = useClasses();

	return (
		<Layout>
			<SEO title={content.title} />

			<div className={classes.page}>
				<div className={classes.container}>
					<section>
						<h1 className={classes.header}>{content.title}</h1>
						<p>{content.subtitle}</p>
					</section>

					<section>
						<h3 className={classes.subheader}>{content.responsiblePerson.title}</h3>
						<p>{content.responsiblePerson.description}</p>
						<h4 className={classes.nameHeader}>{content.responsiblePerson.company.title}</h4>
						{content.responsiblePerson.company.paragraphs.map((paragraph, key) => (
							<p key={key} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
						))}
					</section>

					<section>
						<h3 className={classes.subheader}>{content.dataProcessed.title}</h3>
						<ul>
							{content.dataProcessed.listItems.map((item, key) => (
								<li key={key}>{item}</li>
							))}
						</ul>
					</section>

					<section>
						<h3 className={classes.subheader}>{content.dataSubjects.title}</h3>
						<p>{content.dataSubjects.description}</p>
					</section>

					<section>
						<h3>{content.processing.title}</h3>
						<ul>
							{content.processing.listItems.map((item, key) => (
								<li key={key}>{item}</li>
							))}
						</ul>
					</section>

					{content.restOfSections.map((item, key) => (
						<section key={key}>
							<h3>{item.title}</h3>
							{item.paragraphs.map((paragraph, i) => (
								<p key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
							))}
						</section>
					))}

					<section>
						<h3>{content.application.title}</h3>
						<p>{content.application.paragraph}</p>
						{content.application.sections.map((item, key) => (
							<div key={key}>
								<h4>{item.title}</h4>
								<p>{item.paragraph}</p>
							</div>
						))}
					</section>

					{content.continueOfRestOfSections.map((item, key) => (
						<section key={key}>
							<h3>{item.title}</h3>
							{item.paragraphs.map((paragraph, i) => (
								<p key={i} dangerouslySetInnerHTML={{ __html: paragraph }}></p>
							))}
						</section>
					))}

					<p className={classes.created}>{content.created}</p>
				</div>
			</div>
		</Layout>
	);
};

export default DataProtection;

const useClasses = makeStyles({
	page: {
		backgroundColor: Colors.offerBackgroundBlack,
		color: Colors.whiteLightlyDimmed,
		fontFamily: FiraSans.regular,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		paddingTop: '3.5em',
		paddingBottom: '5.75em',
		paddingLeft: '0.8em',
		paddingRight: '0.8em',
		whiteSpace: 'pre-wrap',
		minHeight: 'calc(100vh - 9.2em)',
		boxSizing: 'border-box',
		'& a': {
			textDecoration: 'underline',
			'@media (max-width: 599px)': {
				wordBreak: 'break-word',
			},
		},
		'& ul': {
			paddingLeft: '0.785em',
		},
		'& li': {
			listStyleType: 'none',
		},
		'& li:before': {
			content: "'– '",
			position: 'absolute',
			transform: 'translate(-0.785em)',
		},
		'& p': {
			margin: '0.8em 0',
		},
	},
	container: {
		maxWidth: narrowWidth,
		margin: '0 auto',
	},
	header: {
		fontSize: Dimensions.largeTitle.fontSize,
		lineHeight: Dimensions.largeTitle.lineHeight,
		color: Colors.white,
		marginTop: 0,
		marginBottom: '0.5em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularTitle.fontSize,
			lineHeight: Dimensions.regularTitle.lineHeight,
		},
	},
	subheader: {
		color: Colors.white,
		marginTop: '1.25em',
		marginBottom: '0.625em',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.largeText.fontSize,
			lineHeight: Dimensions.largeText.lineHeight,
		},
	},
	nameHeader: {
		marginTop: '1em',
		marginBottom: '0.16666666666666666em',
	},
	created: {
		marginTop: '2em !important',
		marginBottom: 0 + ' !important',
	},
});
